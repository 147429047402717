import NewFundraiserForm from "../components/forms/NewFundraiserForm";


const FundraisePage: React.FC = () => {



    return (
        <div><NewFundraiserForm /></div>
    )
}


export default FundraisePage;